header {
  padding-top: 4rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* =============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* =============== POINTER  ============== */
.typing-effect {
  text-align: center;
}

.typing-effect-text h2 {
  font-size: 1.7rem !important;
}


.typing-effect-text {
  display: inline-block;
  vertical-align: middle;
  color: var(--color-white);
  letter-spacing: 2px;
}

#typing-effect-cursor {
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 50px;
  background-color: var(--color-white);
  animation: blink .75s step-end infinite;
}

@keyframes blink {
  from, to {
    background-color: transparent;
  }
  50% {
    background-color: var(--color-white);;
  }
}

/* =============== HEADER SIDES ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header__switches {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 3rem;
}
.header__switches::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}
.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 750px) {
  header {
    padding-top: 4rem;
  }
  .mobile__switches {
    display: flex;
    justify-content: flex-end;
  }
  .header__socials {
    display: none;
  }

  .header__switches {
    display: none;
  }
  .cta {
    margin-top: 1.7rem;
    gap: 1rem;
  }
}
