.light-mode {
  --color-bg: #f3f1f1;
  --color-bg-variant: #f3cd9c;
  --color-primary: #ca6702;
  --color-primary-variant: #38a3a5;
  --color-white: #000000;
  --color-light: rgba(0, 0, 0, 0.6);
}

#dark-light-icon{
  cursor: pointer;
}

.lightmode-switcher {
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 50px;
  height: 24px;
  padding: 5px;
  border-radius: 12px;
  cursor: pointer;
}
.glowing-icon {
  animation: icon-animation-glow 3s linear infinite;
}
.lightmode-switcher:hover {
  color: var(--color-white);
}

.light-switcher-icon {
  width: 2em;
  height: 2em;
  color: var(--color-primary);
}

.switcher-light-dark .icon-size {
  animation: icon-animation 1s linear;
}

@keyframes icon-animation {
  0% {
    transform: rotate(0deg);
    color: var(--color-white);
  }
  50% {
    transform: rotate(180deg);
    color: var(--color-primary);
  }
  75% {
    color: var(--color-white);
  }
  100% {
    transform: rotate(360deg);
    color: var(--color-white);
  }
}

@keyframes icon-animation-glow {
  0% {
    color: var(--color-white);
  }
  50% {
    color: var(--color-primary);
  }
  100% {
    color: var(--color-white);
  }
}
