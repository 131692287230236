.project__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.project__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.project__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.project__item-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.project__item-label {
  border: 2px solid transparent;
  background: #000000;
  color: var(--color-white);
  padding: 0.1em 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
}

.project__item-label:hover {
  background: var(--color-white) !important;
  color: var(--color-bg) !important;
  border-color: transparent !important;
  transition: var(--transition);
}


.project__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}
.project__image {
  width: 100%;
  height: 242px;
}
.project__item h3 {
  margin: 1.2rem 0 2rem;
}

.project__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

@keyframes icon-animation-glow {
  0% {
    color: var(--color-white);
  }
  50% {
    color: var(--color-primary);
  }
  100% {
    color: var(--color-white);
  }
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .project__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .project__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.live-icon {
  animation: live-glow 2s linear infinite;
}
@keyframes live-glow {
  0% {
    color:var(--color-bg-variant);
  }
  50% {
    color: transparent;
  }
  100% {
    color: var(--color-bg-variant);
  }
}
